import React, { useRef, useState, useEffect } from 'react';
import PubSub from 'pubsub-js'

const CtaRandomXY = ({input}) => {
  const [isMobile, setIsMobile] = useState(undefined)

  const {
    url,
    target
  } = input
  // console.log(input)
  // console.log(url)
  const liensRef = useRef()
  const [rand, setRand] = useState({
    left: 50, top: 50
  })

  useEffect(() => {
    if('ontouchstart' in window && window.innerWidth <= 768){
      setIsMobile(true)
    }else{
      setIsMobile(false)
    }

    _placeLink()
    const token = PubSub.subscribe("SLIDER_INDEX", () => {
      _placeLink()
    })

    return () => PubSub.unsubscribe(token)
  }, [])

  const _placeLink = () => {
    const {width, height} = liensRef.current.getBoundingClientRect()
    const centerX =  isMobile ? window.innerWidth/4 : window.innerWidth/2
    const thirdX = window.innerWidth/4
    const centerY =  window.innerHeight/2
    const thirdY = window.innerHeight/4
// console.log(entierAleatoire(-1, 1))
    let left = centerX + (entierAleatoire(-1, 1) * thirdX)
    let top = centerY + (entierAleatoire(-1, 1) * thirdY)
    if(left + width > window.innerWidth){
      left = window.innerWidth - width
    }
    // console.log(width,height)
    // const left = Math.round(Math.random() * window.innerWidth ) - width
    // const top = Math.round(Math.random() * ((window.innerHeight/3)*2)) - height
    setRand({
      ...rand,
      left: left,
      top: top
    })
  }

  const entierAleatoire = (min, max) => {
    const arr = [min, max]
    const rand = Math.floor(Math.random() * arr.length)
    return arr[rand]
  }

  return (
    // <div>test</div>
    <a target="_blank" href={url} className="project-liens" ref={liensRef} style={{
      transform: `translate(${rand.left}px, ${rand.top}px)`
    }}><span>{url.replace("https://", "")}</span></a>
  );
};

export default CtaRandomXY;