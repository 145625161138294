import React, { useEffect, useState } from 'react';
import VideoPlayer from '../ui/VideoPlayer'
// import ScreenSaverDvd from '../ui/ScreenSaverDvd'
import Div100vh from 'react-div-100vh'

const Video = ({input}) => {
  // console.log(input)
  const [play, setPlay] = useState(false)
// console.log(play)
  const {
    video,
    size,
    background
  } = input.primary

  // const _getWidth = (image, size) => {
  //   return size ? `calc(${(size * image.fluid.aspectRatio)}vh` : '50vh'
  // }

  useEffect(() => {
    // console.log(index)
    const token = PubSub.subscribe("SLIDER_INDEX", (e, d) => {
      // console.log(d.slide)
      if(d.slide && d.slide.slice_type === "video"){
        // console.log(d.slide.id, input.id)
        setPlay(d.slide.id === input.id)
        // if(d.id === input.id){}
      }
    })

    return () => PubSub.unsubscribe(token)
  }, [])

  const _getBg = () => {
    return background ? background : 'transparent'
  }

  return (
    <div className="slice video" style={{
      backgroundColor: _getBg()
    }}>
      <Div100vh>
        <VideoPlayer 
        input={video.url} 
        size={size || 'cover'} 
        play={play}
        />
        {/* <pre className="dbg">play : {play}</pre> */}
      </Div100vh>
    </div>
  );
};

export default Video;