import React, {useState, useEffect, useCallback, useRef} from 'react';
import PubSub from 'pubsub-js'
import Slider from "react-slick";
import {throttle, debounce} from 'throttle-debounce'
import Div100vh from 'react-div-100vh'
import Images from "./Images";
import ImagesFluid from "./ImagesFluid";
import ImagesFluidSlider from "./ImagesFluidSlider";
import FullImage from "./FullImage";
import Video from "./Video";

const SlicesSlider = ({input}) => {
  const [current, setCurrent] = useState();
  const [length, setLength] = useState(input.length || 0);
  const [isMobile, setIsMobile] = useState(undefined)
  const [isEnd, setIsEnd] = useState(false)

  const slider = useRef()
  // const currentIndexRef = useRef();

  useEffect(() => {
    if('ontouchstart' in window && window.innerWidth <= 768){
      setIsMobile(true)
    }else{
      setIsMobile(false)
    }

    const _onWheelDebounce = debounce(100, true, _onWheel);
    document.body.addEventListener('wheel', _onWheelDebounce)

    return () => {
      document.body.removeEventListener('wheel', _onWheelDebounce)
    }
    
  }, [])

  useEffect(() => {
    // console.log(current, length)
    PubSub.publish("SLIDER_INDEX", {
      current: current ? (length - (current-1)) : length,
      length: length,
      slide: input[(current - 1)]
    })

    _handlePreload()

    setIsEnd(current === length)
    
  }, [current, length])

  useEffect(() => {
    // console.log("isEnd",isEnd)

    if(isEnd){
      document.querySelector(".slick-next").addEventListener('click', _triggerEnd)
      setTimeout(() => {
        document.body.addEventListener('wheel', _triggerEnd)
      }, 2000)
    }else{
      document.body.removeEventListener('wheel', _triggerEnd)
      document.querySelector(".slick-next").removeEventListener('click', _triggerEnd)
    }
    return () => {
      document.body.removeEventListener('wheel', _triggerEnd)
      document.querySelector(".slick-next").removeEventListener('click', _triggerEnd)
    }
  }, [isEnd])

  const _triggerEnd = (e) => {
    if(e && e.type === "wheel" && e.deltaY < 0)return
    PubSub.publish("SLIDER_END")
  }

  const _onEdge = () => {
    _triggerEnd()
  }

  const _onWheel = (e) => {
    console.log(isEnd, e)
    if(e.deltaY > 0){
      _next()
      // if(isEnd)_triggerEnd()
      // _handleIsEnd()
    }else{
      _prev()
    }
  }

  // const _onClickNext = () => {
  //   console.log(currentIndexRef.current, length, isEnd)
  //   _handleIsEnd()
  // }

  // const _handleIsEnd = () => {
  //   // console.log(currentIndexRef.current, length)
  //   setIsEnd((currentIndexRef.current + 1) === length)
  // }

  const _renderSlices = (slices) => {
    // console.log(slices)
    const _slice = slices.map((slice, i) => {
      if(slice === null)return null
      // console.log(slice.slice_type)
      switch (slice.slice_type) {
        case "images":
          return <Images key={i} input={slice} context="slider"/>;
        case "images_fluid":
          return <ImagesFluid key={i} input={slice} context="slider" />;
        case "images_fluid_slider":
          return <ImagesFluidSlider key={i} input={slice} context="slider" />;
        case "full_image":
          return <FullImage key={i} input={slice} context="slider" />;
        case "video":
          return <Video key={i} input={slice} />;
        default:
          return null;
      }
    });

    return _slice;
  }

  const _handlePreload = () => {
    if(current && current < length){
      // console.log(current)
      // console.log(input[current])
      if(input[current].slice_type === "images" || input[current].slice_type === "images_fluid_slider"){
        input[current].items.forEach(el => {
          _preloadImg(el.image.url)
        })
      }
      if(input[current].slice_type === "full_image"){
        _preloadImg(input[current].primary.image.url)
      }
    }
    
  }

  const _preloadImg = (url) => {
    const img = new Image()
    img.src = url
  }

  const _sliderAfterChange = (current) => {
    setCurrent((current+1))
  }

  const _sliderBeforeChange = () => {
    const imagesDouble = document.querySelector(".images-double")
    const imagesSingle = document.querySelectorAll(".images-single")
    if(imagesDouble && imagesSingle){
      const {height} = imagesDouble.getBoundingClientRect()
      imagesSingle.forEach(el => {
        el.style.height = height+"px"
      })
    }
  }

  const _prev = () => {
    slider.current.slickPrev();
  }

  const _next = () => {
    slider.current.slickNext();
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: isMobile ? 200 : 0,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    // variableWidth: true,
    swipe: true,
    fade: !isMobile,
    // lazyLoad: true,
    onInit: () => setCurrent(1),
    // beforeChange: (current, next) => setCurrent(current),
    // afterChange: current => setCurrent((current+1))
    beforeChange: _sliderBeforeChange,
    afterChange: _sliderAfterChange,
    // onSwipe: _onSwipe
    onEdge: _onEdge
  };

  return (
    <div className={"slices slices-slider"}>
      <Div100vh>
        <Slider ref={slider} {...settings}>
          {input && _renderSlices(input)}
        </Slider>

        {/* {current === length &&
          <div className="btn-show-body" onClick={() => PubSub.publish("SLIDER_END")}></div>
        } */}
      </Div100vh>
      
    </div>
  );
};

export default SlicesSlider;