import React, { useEffect, useMemo } from "react"
import { graphql } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"
// import Img from "gatsby-image"
// import { linkResolver } from 'gatsby-source-prismic-graphql';
// import { RichText } from "prismic-reactjs"
// import Img from "gatsby-image"
//import PubSub from 'pubsub-js';
import SEO from "../components/seo"
import SlicesSlider from "../components/slices/SlicesSlider"
import SlicesScroller from "../components/slices/SlicesScroller"
import CtaRandomXY from "../components/ui/CtaRandomXY"

export const pageQuery = graphql`
  query ProjectBySlug($uid: String!, $lang: String!) {
    prismicProject(uid: { eq: $uid }, lang: { eq: $lang }) {
      data {
        ...project
      }
    }
  }
`

const IS_BROWSER = typeof window !== "undefined"

// export default function PageProject({ pageContext: {alternates}, data: staticData }) {
const PageProject = ({ path, data: staticData }) => {
  const data = useMemo(() => {
    // If we're not in a browser (i.e. we're in SSR) or preview data has not been
    // set, use the non-preview static data.
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData

    return mergePrismicPreviewData({
      staticData,
      previewData: window.__PRISMIC_PREVIEW_DATA__,
    })
  }, [staticData])

  // console.log(data)
  const {
    title,
    seo_description,
    texte,
    image_featured,
    categorie,
    look,
    liens,
    background,
    body,
  } = data.prismicProject.data
  // console.log(data.prismicProject.data)

  useEffect(() => {
    PubSub.publish("PROJECT_TEXTE", texte)
  }, [])

  const _getBg = () => {
    return background ? background : "white"
  }
  // console.log(image_featured.url)
  return (
    <div
      className="page-template page-project"
      style={{
        backgroundColor: _getBg(),
      }}
    >
      <SEO
        pageTitle={title.text}
        pageDescription={seo_description.text}
        pageImage={image_featured.url}
        pathname={path}
        template={`template-project look-${look}`}
        page={true}
      />

      {body && look === "slider" && <SlicesSlider input={body} />}
      {body && look === "scroll" && <SlicesScroller input={body} />}

      <div
        className="project-header"
        onClick={() => PubSub.publish("NAV_TOGGLE", true)}
      >
        <div className="container-fluid ">
          <div className="row">
            <div className="col-md-3 col-xs-12">
              <div className="x xab">
                {categorie.document && categorie.document.data && (
                  <div className={`categorie fS`}>
                    {categorie.document.data.title.text}
                  </div>
                )}

                <h1>
                  <div className="dot">•</div>
                  <div className="title fM link_gradient">{title.text}</div>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {liens && liens.url && <CtaRandomXY input={liens} />}
    </div>
  )
}

export default PageProject
