import React, { useState, useEffect } from "react";
import Img from "gatsby-image";
import ImageProtect from '../ui/ImageProtect'
import Div100vh from 'react-div-100vh'

const ImagesFluidSlider = ({ input, context }) => {
  // console.log(input.items)
  const [isMobile, setIsMobile] = useState(false)
  
  useEffect(() => {
    const _isMobile = window.innerWidth <= 768
    setIsMobile(_isMobile)
    
  }, [])

  const {
    background
  } = input.primary

  // const _getWidth = (image, size) => {
  //   return size ? `calc(${(size * image.fluid.aspectRatio)}vh` : '50vh'
  // }

  const _getBg = () => {
    return background ? background : 'transparent'
  }

  // const _getTop = (top, bottom) => {
  //   if(top !== null)return top+"%"
  //   else if(bottom !== null)return 'unset'
  //   else if(top == null && bottom == null)return '0'
  //   // else return '18vh'
  // }

  // const _getBottom = (top, bottom) => {
  //   if(bottom !== null)return bottom+"%"
  //   else if(top !== null)return 'unset'
  //   else if(bottom == null && top == null)return '0'
  //   // else return '18vh'
  // }

  const _getStyle = (size, size_mobile, positions_desktop, positions_mobile) => {
    const width = isMobile ? size_mobile+'vw' : size+'vw'
    const positions = isMobile ? positions_mobile : positions_desktop
    // console.log(positions_desktop, positions_mobile)
    if(!positions){
      return {
        width: width,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }
    }
    const parts = positions.split(",")
    //["7", "null", "null", "21"]
    const top = parts[0] ? (isMobile ? parts[0]+'vh' : parts[0]+'%') : 'unset'
    const right = parts[1] ? parts[1]+'%' : 'unset'
    const bottom = parts[2] ? parts[2]+'%' : 'unset'
    const left = parts[3] ? parts[3]+'%' : 'unset'
    // console.log(parts)
    return {
      width,
      top,
      right,
      bottom,
      left
    }
  }

  return (
    <div className={`slice images-fluid-slider`} style={{
      backgroundColor: _getBg()
    }}>
      <Div100vh>
      <div className="inner h100" >

          {input.items.map( ({image, size, size_mobile, positions_desktop, positions_mobile, left, top, bottom}, i) => (
            <div className={`images-fluid-slider-item ${bottom === 0 ? 'is-bottom' : ''} ${bottom === null && top === null ? 'is-center' : ''}`} key={i} 
      
            style={
              _getStyle(size, size_mobile, positions_desktop, positions_mobile)
            }
            >
              <Img
                fluid={image.fluid}
                Tag="figure"
                fadeIn={context === "scroll"}
              />
              <ImageProtect />
              {/* <div className="dbg">
                top: {top}, bottom: {bottom}
              </div> */}
            </div>
            
          ))}

      </div>  
      </Div100vh>
    </div>
  );
};

export default ImagesFluidSlider;
